exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-account-tsx": () => import("./../../../src/pages/activate-account.tsx" /* webpackChunkName: "component---src-pages-activate-account-tsx" */),
  "component---src-pages-blog-[blog-details-friendly-name]-tsx": () => import("./../../../src/pages/blog/[blog-details-friendly-name].tsx" /* webpackChunkName: "component---src-pages-blog-[blog-details-friendly-name]-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-calculators-tsx": () => import("./../../../src/pages/calculators.tsx" /* webpackChunkName: "component---src-pages-calculators-tsx" */),
  "component---src-pages-campaign-[campaign-title]-tsx": () => import("./../../../src/pages/campaign/[campaign-title].tsx" /* webpackChunkName: "component---src-pages-campaign-[campaign-title]-tsx" */),
  "component---src-pages-charts-tsx": () => import("./../../../src/pages/charts.tsx" /* webpackChunkName: "component---src-pages-charts-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-compare-products-tsx": () => import("./../../../src/pages/compare-products.tsx" /* webpackChunkName: "component---src-pages-compare-products-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-email-confirmation-tsx": () => import("./../../../src/pages/email-confirmation.tsx" /* webpackChunkName: "component---src-pages-email-confirmation-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-[landing-name]-tsx": () => import("./../../../src/pages/landing/[landing-name].tsx" /* webpackChunkName: "component---src-pages-landing-[landing-name]-tsx" */),
  "component---src-pages-learn-[artical-title]-tsx": () => import("./../../../src/pages/learn/[artical-title].tsx" /* webpackChunkName: "component---src-pages-learn-[artical-title]-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-memberships-tsx": () => import("./../../../src/pages/memberships.tsx" /* webpackChunkName: "component---src-pages-memberships-tsx" */),
  "component---src-pages-my-vault-accounts-tsx": () => import("./../../../src/pages/my-vault/accounts.tsx" /* webpackChunkName: "component---src-pages-my-vault-accounts-tsx" */),
  "component---src-pages-my-vault-addresses-tsx": () => import("./../../../src/pages/my-vault/addresses.tsx" /* webpackChunkName: "component---src-pages-my-vault-addresses-tsx" */),
  "component---src-pages-my-vault-buy-back-order-history-tsx": () => import("./../../../src/pages/my-vault/buy-back-order-history.tsx" /* webpackChunkName: "component---src-pages-my-vault-buy-back-order-history-tsx" */),
  "component---src-pages-my-vault-index-tsx": () => import("./../../../src/pages/my-vault/index.tsx" /* webpackChunkName: "component---src-pages-my-vault-index-tsx" */),
  "component---src-pages-my-vault-order-history-tsx": () => import("./../../../src/pages/my-vault/order-history.tsx" /* webpackChunkName: "component---src-pages-my-vault-order-history-tsx" */),
  "component---src-pages-my-vault-private-holding-add-tsx": () => import("./../../../src/pages/my-vault/private-holding-add.tsx" /* webpackChunkName: "component---src-pages-my-vault-private-holding-add-tsx" */),
  "component---src-pages-my-vault-private-holding-tsx": () => import("./../../../src/pages/my-vault/private-holding.tsx" /* webpackChunkName: "component---src-pages-my-vault-private-holding-tsx" */),
  "component---src-pages-news-[news-details-friendly-name]-tsx": () => import("./../../../src/pages/news/[news-details-friendly-name].tsx" /* webpackChunkName: "component---src-pages-news-[news-details-friendly-name]-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-order-details-tsx": () => import("./../../../src/pages/order-details.tsx" /* webpackChunkName: "component---src-pages-order-details-tsx" */),
  "component---src-pages-password-recovery-tsx": () => import("./../../../src/pages/password-recovery.tsx" /* webpackChunkName: "component---src-pages-password-recovery-tsx" */),
  "component---src-pages-recently-viewed-products-tsx": () => import("./../../../src/pages/recently-viewed-products.tsx" /* webpackChunkName: "component---src-pages-recently-viewed-products-tsx" */),
  "component---src-pages-registration-tsx": () => import("./../../../src/pages/registration.tsx" /* webpackChunkName: "component---src-pages-registration-tsx" */),
  "component---src-pages-rewardpoints-history-tsx": () => import("./../../../src/pages/rewardpoints/history.tsx" /* webpackChunkName: "component---src-pages-rewardpoints-history-tsx" */),
  "component---src-pages-shipping-calculator-tsx": () => import("./../../../src/pages/shipping-calculator.tsx" /* webpackChunkName: "component---src-pages-shipping-calculator-tsx" */),
  "component---src-pages-shop-[category]-category-tsx": () => import("./../../../src/pages/shop/category/[category].tsx" /* webpackChunkName: "component---src-pages-shop-[category]-category-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-product-product-friendly-name-tsx": () => import("./../../../src/pages/shop/product/[product-friendlyName].tsx" /* webpackChunkName: "component---src-pages-shop-product-product-friendly-name-tsx" */),
  "component---src-pages-shopping-cart-tsx": () => import("./../../../src/pages/shopping-cart.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-tsx" */),
  "component---src-pages-showcase-[showcase-title]-[sub-title]-tsx": () => import("./../../../src/pages/showcase/[showcase-title]/[sub-title].tsx" /* webpackChunkName: "component---src-pages-showcase-[showcase-title]-[sub-title]-tsx" */),
  "component---src-pages-showcase-[showcase-title]-tsx": () => import("./../../../src/pages/showcase/[showcase-title].tsx" /* webpackChunkName: "component---src-pages-showcase-[showcase-title]-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-story-[story-title]-tsx": () => import("./../../../src/pages/story/[story-title].tsx" /* webpackChunkName: "component---src-pages-story-[story-title]-tsx" */),
  "component---src-pages-topic-[topic-name]-tsx": () => import("./../../../src/pages/topic/[topic-name].tsx" /* webpackChunkName: "component---src-pages-topic-[topic-name]-tsx" */),
  "component---src-pages-vault-calculator-tsx": () => import("./../../../src/pages/vault-calculator.tsx" /* webpackChunkName: "component---src-pages-vault-calculator-tsx" */),
  "component---src-pages-watchlist-tsx": () => import("./../../../src/pages/watchlist.tsx" /* webpackChunkName: "component---src-pages-watchlist-tsx" */)
}

